<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Ajouter un nouvel Utilisateur</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nom et Prénom"
            rules="required"
          >
            <b-form-group label="Nom et Prénom" label-for="name">
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Role Utilisateur"
            rules="required"
          >
            <b-form-group
              label="Role Utilisateur"
              label-for="role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                label="name"
                :reduce="(val) => val.id"
                :clearable="false"
                input-id="role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <validation-provider
            #default="validationContext"
            name="mot de passe"
            rules="required|min:8"
          >
            <b-form-group label="Mot de Passe" label-for="password">
              <b-form-input
                id="password"
                v-model="userData.password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="***********"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password confirmation -->
          <validation-provider
            #default="validationContext"
            name="Confirmer Mot de Passe"
            rules="required|confirmed:mot de passe"
          >
            <b-form-group
              label="Confirmer le Mot de Passe"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                v-model="userData.password_confirmation"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="***********"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              Ajouter
            </b-button>
            <b-button
              :disabled="isLoading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, min, confirmed } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      confirmed,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: "",
      email: "",
      role_id: null,
    };

    const isLoading = ref(false);

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const passwordToggleIcon = () => {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    };

    const onSubmit = () => {
      isLoading.value = true;
      store
        .dispatch("user/addUser", userData.value)
        .then((response) => {
          if (response.success) {
            emit("refetch-data");
            emit("update:is-add-new-user-sidebar-active", false);
          } else {
            // handle error content
          }
        })
        .then(() => (isLoading.value = false));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      passwordToggleIcon,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      isLoading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
